import { PhoneIcon, GlobeAltIcon } from "@heroicons/react/24/outline";

export const Taxis = (props) => {
  return (
    <section id="taxis" className="py-12 space-y-6 max-w-xl mx-auto px-4">
      <h2 className="text-2xl font-semibold text-[#e63d68] mb-6 text-center">
        Taxis
      </h2>
      <p>Local Taxi companies:</p>
      <div className="bg-white shadow-lg rounded-lg p-6">
        <h3 className="text-xl font-semibold">A1 Taxis</h3>

        <p className="mt-4 text-center">
          <a
            href="tel:+441727866666"
            className="flex items-center justify-center space-x-2 text-[#e63d68] hover:text-[#cc365c] transition-colors "
          >
            <PhoneIcon className="h-5 w-5" />
            <span className="text-md">01727 866666</span>
          </a>
        </p>
        <p className="mt-4 text-center">
          <a
            href="https://www.a1taxis.net/"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center justify-center btn"
          >
            <GlobeAltIcon className="h-5 w-5 text-white mr-2" />
            <span className="text-md">Book Here</span>
          </a>
        </p>
      </div>

      <div className="bg-white shadow-lg rounded-lg p-6">
        <h3 className="text-xl font-semibold">Gold Line Taxis </h3>

        <p className="mt-4 text-center">
          <a
            href="tel:+441727833333"
            className="flex items-center justify-center space-x-2 text-[#e63d68] hover:text-[#cc365c] transition-colors "
          >
            <PhoneIcon className="h-5 w-5" />
            <span className="text-md">01727 833333</span>
          </a>
        </p>
        <p className="mt-4 text-center">
          <a
            href="https://www.goldlinetaxis.co.uk/"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center justify-center btn"
          >
            <GlobeAltIcon className="h-5 w-5 text-white mr-2" />
            <span className="text-md">Book Here</span>
          </a>
        </p>
      </div>

      <div className="bg-white shadow-lg rounded-lg p-6">
        <h3 className="text-xl font-semibold">Rider Taxis</h3>

        <p className="mt-4 text-center">
          <a
            href="tel:+441727322373"
            className="flex items-center justify-center space-x-2 text-[#e63d68] hover:text-[#cc365c] transition-colors "
          >
            <PhoneIcon className="h-5 w-5" />
            <span className="text-md">01727 322373</span>
          </a>
        </p>
        <p className="mt-4 text-center">
          <a
            href="https://ridertaxis.co.uk/"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center justify-center btn"
          >
            <GlobeAltIcon className="h-5 w-5 text-white mr-2" />
            <span className="text-md">Book Here</span>
          </a>
        </p>
      </div>

      <p>Uber also works in St Albans</p>
    </section>
  );
};
