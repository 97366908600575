export const DressCode = (props) => {
  return (
    <section id="dress-code" className="py-12 space-y-6">
      <h2 className="text-2xl font-semibold text-[#e63d68] mb-6 text-center">
        Dress Code
      </h2>
      <p>Gentlemen black tie / ladies dress to impress!</p>
    </section>
  );
};
