export const Text = (props) => {
  const { label, type, name, value, onChange, required = true } = props;

  return (
    <div className="mb-4">
      <label className="block text-gray-800 font-semibold mb-1">
        {label}:
        <input
          type={type}
          name={name}
          value={value}
          onChange={onChange}
          required={required}
          className="mt-1 block w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#e63d68] focus:border-[#e63d68]"
        />
      </label>
    </div>
  );
};
