import { Text } from "../Field/Text";

export const GuestContact = (props) => {
  const { formData, onChange, showMobile = true } = props;
  return (
    <>
      <Text
        label="First Name"
        name="firstName"
        type="text"
        value={formData["firstName"]}
        onChange={onChange}
      />
      <Text
        label="Last Name"
        name="lastName"
        type="text"
        value={formData["lastName"]}
        onChange={onChange}
      />
      <Text
        label="Email Address"
        name="email"
        type="email"
        value={formData["email"]}
        onChange={onChange}
      />
      {showMobile && (
        <Text
          label="Mobile Number"
          name="mobile"
          type="text"
          value={formData["mobile"]}
          onChange={onChange}
        />
      )}
    </>
  );
};
