import React, { useState } from "react";
import { loader } from "graphql.macro";
import { useMutation } from "@apollo/client";
import { Error, GuestContact, PlusOneContact, Submit } from "../Parts";
import { PlusOneMeal } from "../Parts/PlusOneMeal";
import { GuestMeal } from "../Parts/GuestMeal";

const createFullGuestPlusOne = loader(
  "../../../gql/mutation/createFullGuestPlusOne.gql"
);

export const FullGuestPlus = (props) => {
  const [addGuest, { loading, error }] = useMutation(createFullGuestPlusOne);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    menuType: "",
    dietary: "",
    plusOneFirstName: "",
    plusOneLastName: "",
    plusOneMenuType: "",
    plusOneDietary: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = await addGuest({
      variables: { input: { ...formData } },
    });
    if (result.data?.createFullGuestPlusOne?.success) {
      props.onSuccess();
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {error && <Error />}
      <GuestContact onChange={handleChange} formData={formData} />
      <GuestMeal onChange={handleChange} formData={formData} />

      <h3 className="text-lg font-semibold text-gray-800 mt-6 mb-4">
        Plus One Details
      </h3>

      <PlusOneContact onChange={handleChange} formData={formData} />
      <PlusOneMeal onChange={handleChange} formData={formData} />

      <Submit loading={loading} />
    </form>
  );
};
