import logo from "../../images/logo.svg";
import styles from "./HeroBanner.module.css";

export const HeroBanner = (props) => {
  return (
    <header className="bg-hero-image relative flex items-center justify-center h-screen bg-cover bg-center">
      <div className="absolute inset-0 bg-black opacity-60"></div>

      <div className="absolute top-8 left-1/2 transform -translate-x-1/2 z-20">
        <img src={logo} alt="logo" className={`${styles.logo} w-32`} />
      </div>

      <div className="relative z-10 text-center text-white p-6 mt-20">
        {props.children}
      </div>
    </header>
  );
};
