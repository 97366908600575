import { GlobeAltIcon } from "@heroicons/react/24/outline";

export const Accommodation = (props) => {
  return (
    <section
      id="accommodation"
      className="py-12 space-y-6 max-w-xl mx-auto px-4"
    >
      <h2 className="text-2xl font-semibold text-[#e63d68] mb-6 text-center">
        Accommodation
      </h2>
      <p>
        If you have already booked a room at St Michael’s Manor, then you are
        all sorted.
      </p>
      <p>
        If you haven’t, then unfortunately, all the rooms at St Michael’s Manor
        are already booked, however if you would like to stay locally there are
        a few hotels to choose from:
      </p>
      <div className="bg-white shadow-lg rounded-lg p-6">
        <h3 className="text-xl font-semibold">
          The White Hart Hotel{" "}
          <span className="text-sm text-gray-500">(12-minute walk)</span>
        </h3>
        <p className="text-md text-gray-700">
          23-25 Holywell Hill, St Albans AL1 1EZ
        </p>
        <p className="mt-2 text-md text-gray-600">
          <a
            href="https://www.whiteharthotelstalbans.co.uk/"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center justify-center btn"
          >
            <GlobeAltIcon className="h-5 w-5 text-white mr-2" />
            Book Here
          </a>
        </p>
      </div>

      <div className="bg-white shadow-lg rounded-lg p-6">
        <h3 className="text-xl font-semibold">
          The Samuel Ryder Hotel{" "}
          <span className="text-sm text-gray-500">(13-minute walk)</span>
        </h3>
        <p className="text-md text-gray-700">
          27 Holywell Hill, St Albans AL1 1HG
        </p>
        <p className="mt-2 text-md text-gray-600">
          <a
            href="https://www.hilton.com/en/hotels/ltnsrup-the-samuel-ryder-hotel-st-albans/"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center justify-center btn"
          >
            <GlobeAltIcon className="h-5 w-5 text-white mr-2" />
            Book Here
          </a>
        </p>
      </div>

      <div className="bg-white shadow-lg rounded-lg p-6">
        <h3 className="text-xl font-semibold">
          Travelodge{" "}
          <span className="text-sm text-gray-500">(18-minute walk)</span>
        </h3>
        <p className="text-md text-gray-700">
          53 St Peter’s St, St Albans AL1 3DY
        </p>
        <p className="mt-2 text-md text-gray-600">
          <a
            href="https://www.travelodge.co.uk/hotels/687/St-Albans-City-Centre-hotel"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center justify-center btn"
          >
            <GlobeAltIcon className="h-5 w-5 text-white mr-2" />
            Book Here
          </a>
        </p>
      </div>
    </section>
  );
};
