export const WeddingGifts = (props) => {
  return (
    <section
      id="wedding-gifts"
      className="py-12 space-y-6 max-w-xl mx-auto px-4"
    >
      <h2 className="text-2xl font-semibold text-[#e63d68] mb-6 text-center">
        Wedding Gifts
      </h2>
      <p>All we are expecting at the wedding is you.</p>
      <p>
        If you would like to give something, a contribution towards excursions
        for our honeymoon would be greatly appreciated.
      </p>
      <p>Thank you</p>
    </section>
  );
};
