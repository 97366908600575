import React, { useState } from "react";
import { loader } from "graphql.macro";
import { Error, GuestContact, Submit } from "../Parts";

import { useMutation } from "@apollo/client";
const createNotAttendingGuest = loader(
  "../../../gql/mutation/createNotAttendingGuest.gql"
);

export const NotAttendingGuest = (props) => {
  const [addGuest, { loading, error }] = useMutation(createNotAttendingGuest);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = await addGuest({
      variables: {
        input: { ...formData },
      },
    });
    if (result.data?.createNotAttendingGuest?.success) {
      props.onSuccess();
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {error && <Error />}
      <GuestContact
        onChange={handleChange}
        formData={formData}
        showMobile={false}
      />
      <Submit loading={loading} />
    </form>
  );
};
