import React, { useState } from "react";
import { loader } from "graphql.macro";
import { Error, GuestContact, Submit } from "../Parts";

import { useMutation } from "@apollo/client";
const createEveningGuest = loader(
  "../../../gql/mutation/createEveningGuest.gql"
);

export const EveningGuest = (props) => {
  const [addGuest, { loading, error }] = useMutation(createEveningGuest);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = await addGuest({
      variables: {
        input: { ...formData },
      },
    });
    if (result.data?.createEveningGuest?.success) {
      props.onSuccess();
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {error && <Error />}
      <GuestContact onChange={handleChange} formData={formData} />
      <Submit loading={loading} />
    </form>
  );
};
