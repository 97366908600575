export const Select = (props) => {
  const { label, name, value, onChange, options } = props;

  return (
    <div className="mb-4">
      <label className="block text-gray-800 font-semibold mb-1">
        {label}:
        <select
          name={name}
          value={value}
          onChange={onChange}
          required
          className="mt-1 block w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#e63d68] focus:border-[#e63d68]"
        >
          {options.map((option, i) => (
            <option key={i} value={option === "Select" ? "" : option}>
              {option}
            </option>
          ))}
        </select>
      </label>
    </div>
  );
};
