import { useState } from "react";
import { scroller } from "react-scroll";
import { FullGuestPlus } from "./FullGuestPlus/FullGuestPlus";
import { FullGuest } from "./FullGuest/FullGuest";
import { EveningGuestPlus } from "./EveningGuestPlus/EveningGuestPlus";
import { EveningGuest } from "./EveningGuest/EveningGuest";
import { NotAttendingGuest } from "./NotAttendingGuest/NotAttendingGuest";

export const RSVP = (props) => {
  return (
    <section id="rsvp" className="py-12 space-y-6">
      <h2 className="text-2xl font-semibold text-[#e63d68] mb-6 text-center">
        R.S.V.P.
      </h2>
      <RsvpContent guestType={props.guestType} />
    </section>
  );
};

const RsvpContent = (props) => {
  const [attending, setAttending] = useState(undefined);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const onFormSuccess = () => {
    setFormSubmitted(true);
    scroller.scrollTo("rsvp", {
      duration: 500,
      smooth: "easeInOutQuad",
      offset: -50,
    });
  };

  const guestType = () =>
    attending === true ? props.guestType : "NOT_ATTENDING";

  if (formSubmitted) {
    return (
      <h3 className="text-xl text-black mb-6 text-center">
        Thank you for your response!
      </h3>
    );
  }

  return (
    <div className="max-w-[600px] w-full mx-auto p-6 bg-white shadow-lg rounded-lg">
      <CheckAttending attending={attending} setAttending={setAttending} />
      {attending !== undefined && (
        <div className="mt-10">
          <GuestForm onFormSuccess={onFormSuccess} guestType={guestType()} />
        </div>
      )}
    </div>
  );
};

const CheckAttending = (props) => {
  const { attending, setAttending } = props;
  return (
    <div className="space-y-4">
      <label className="flex items-center justify-between space-x-3 text-gray-800 font-semibold">
        I am planning to attend
        <input
          type="radio"
          name="attending"
          checked={attending === true}
          value={true}
          onChange={() => setAttending(true)}
          className="hidden peer"
        />
        <div className="w-6 h-6 border-2 border-[#e63d68] rounded-full flex items-center justify-center peer-checked:bg-[#e63d68]">
          <div className="w-3 h-3 bg-white rounded-full"></div>
        </div>
      </label>
      <label className="flex items-center justify-between space-x-3 text-gray-800 font-semibold">
        Unfortunately, I cannot make it
        <input
          type="radio"
          name="attending"
          checked={attending === false}
          value={false}
          onChange={() => setAttending(false)}
          className="hidden peer"
        />
        <div className="w-6 h-6 border-2 border-[#e63d68] rounded-full flex items-center justify-center peer-checked:bg-[#e63d68]">
          <div className="w-3 h-3 bg-white rounded-full"></div>
        </div>
      </label>
    </div>
  );
};

const formComponents = {
  FULL_GUEST_PLUS_ONE: (onSuccess) => <FullGuestPlus onSuccess={onSuccess} />,
  FULL_GUEST: (onSuccess) => <FullGuest onSuccess={onSuccess} />,
  EVENING_GUEST_PLUS_ONE: (onSuccess) => (
    <EveningGuestPlus onSuccess={onSuccess} />
  ),
  EVENING_GUEST: (onSuccess) => <EveningGuest onSuccess={onSuccess} />,
  NOT_ATTENDING: (onSuccess) => <NotAttendingGuest onSuccess={onSuccess} />,
};

const GuestForm = (props) => {
  const { guestType, onFormSuccess } = props;
  const formCreator = formComponents[guestType];

  if (!formCreator) {
    return <p>Unrecognised guest type</p>;
  }

  return formCreator(onFormSuccess);
};
