export const OrderOfTheDay = (props) => {
  const fullSchedule = [
    { time: "13:30", event: "Wedding Ceremony", guests: "DAY" },
    { time: "14:00", event: "Drinks & Canapes", guests: "DAY" },
    { time: "15:45", event: "Wedding Breakfast", guests: "DAY" },
    { time: "17:45", event: "Speeches", guests: "DAY" },
    { time: "18:30", event: "Evening Guests Arrive", guests: "BOTH" },
    { time: "19:00", event: "Cake Cut & First Dance", guests: "BOTH" },
    { time: "19:15", event: "Time to Party!", guests: "BOTH" },
    { time: "21:00", event: "Evening Buffet", guests: "BOTH" },
    { time: "23:30", event: "Last Orders", guests: "BOTH" },
    { time: "00:00", event: "Home Time", guests: "BOTH" },
  ];

  const isEveningGuest = props.guestType?.includes("EVENING_GUEST");
  const order = fullSchedule.filter((item) =>
    isEveningGuest ? item.guests !== "DAY" : true
  );

  return (
    <section
      id="order-of-the-day"
      className="py-12 space-y-6 max-w-xl mx-auto px-4"
    >
      <h2 className="text-2xl font-semibold text-[#e63d68] mb-6 text-center">
        Order of the Day
      </h2>
      <div className="space-y-4">
        {order.map((item, index) => (
          <div
            key={item.time}
            className={`flex justify-between text-xl font-medium py-3 px-4 rounded-lg ${
              index % 2 === 0
                ? "bg-[#e63d68] bg-opacity-20"
                : "bg-white bg-opacity-60"
            }`}
          >
            <span>{item.time}</span>
            <span>{item.event}</span>
          </div>
        ))}
      </div>
    </section>
  );
};
