import { useLazyQuery } from "@apollo/client";
import { useId, useState } from "react";
import { Button } from "../UI/Button";
import { loader } from "graphql.macro";
import { HeroBanner } from "../HeroBanner/HeroBanner";

const queryCheckGuestType = loader("../../gql/query/checkGuestType.gql");

export const PasswordInput = (props) => {
  const { setGuestType, urlId } = props;

  return (
    <HeroBanner>
      <h2 className="text-2xl font-bold text-shadow">The Wedding of</h2>
      <h1 className="text-5xl font-bold mb-6 text-shadow">Sarah & Graham</h1>
      {urlId ? (
        <PasswordField urlId={urlId} setGuestType={setGuestType} />
      ) : (
        <NoUrlId />
      )}
    </HeroBanner>
  );
};

const PasswordField = (props) => {
  const { setGuestType, urlId } = props;
  const id = useId();
  const [password, setPassword] = useState("");
  const [showError, setShowError] = useState(false);
  const [checkGuestType, { loading, error }] =
    useLazyQuery(queryCheckGuestType);

  const submit = async () => {
    const result = await checkGuestType({
      variables: { input: { password: password, urlId } },
    });

    const guestType = result.data?.checkGuestType?.guestType ?? undefined;
    setShowError(guestType === undefined);
    setGuestType(guestType);
  };

  return (
    <>
      <Label id={id} showError={showError || error} />
      <div className="flex items-center justify-center">
        <div className="flex items-center space-x-2 p-6 rounded-lg shadow-lg">
          <input
            type="password"
            id={id}
            value={password}
            disabled={loading}
            onInput={(e) => setPassword(e.target.value)}
            className="w-full px-4 py-2 text-gray-700 bg-gray-100 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#e63d68] focus:border-[#e63d68]"
            onKeyDown={(e) => {
              if (e.key === "Enter") submit();
            }}
          />
          <Button disabled={loading} clickHandler={submit}>
            Submit
          </Button>
        </div>
      </div>
    </>
  );
};

const Label = (props) => {
  const { showError, id } = props;
  return (
    <label htmlFor={id} className="block text-xl mb-2 text-shadow">
      {showError ? (
        <>
          Oops! Something went wrong.
          <br />
          Please check the password and try again
        </>
      ) : (
        <>Please enter the password to continue</>
      )}
    </label>
  );
};

const NoUrlId = () => {
  return (
    <div className="grid grid-cols-2 gap-4 max-w-sm mx-auto">
      <a
        href="/day-guest-plus"
        className="flex justify-center items-center text-center btn"
      >
        Day Guest
        <br />
        (Plus One)
      </a>
      <a
        href="/day-guest"
        className="flex justify-center items-center text-center btn"
      >
        Day Guest
      </a>
      <a
        href="/evening-guest-plus"
        className="flex justify-center items-center text-center btn"
      >
        Evening Guest
        <br />
        (Plus One)
      </a>
      <a
        href="/evening-guest"
        className="flex justify-center items-center text-center btn"
      >
        Evening Guest
      </a>
    </div>
  );
};
