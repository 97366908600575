export const FAQ = (props) => {
  const isEveningGuest = props.guestType?.includes("EVENING_GUEST");
  console.log(isEveningGuest);
  return (
    <section id="faq" className="py-12 space-y-6 max-w-xl mx-auto px-4">
      <h2 className="text-2xl font-semibold text-[#e63d68] mb-6 text-center">
        FAQ
      </h2>
      <div className="space-y-8">
        <div>
          <h3 className="text-l font-semibold">When is the RSVP deadline?</h3>
          <p className="text-gray-700">
            Please RSVP by{" "}
            <span className="font-bold">Friday 31 January 2025</span> at the
            latest.
          </p>
        </div>

        <div>
          <h3 className="text-l font-semibold">Where is the wedding?</h3>
          <p className="text-gray-700">
            The ceremony, wedding breakfast, and reception are all being held at
            St Michael’s Manor. So, once you arrive, you don’t have to worry
            about going anywhere else.{" "}
            <a
              href="https://stmichaelsmanor.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-[#e63d68] hover:underline"
            >
              Hotel website.
            </a>
          </p>
        </div>

        <div>
          <h3 className="text-l font-semibold">Can I bring someone with me?</h3>
          <p className="text-gray-700">
            Please check your invite for your +1 details.
          </p>
        </div>

        <div>
          <h3 className="text-l font-semibold">Where can I park?</h3>
          <p className="text-gray-700">
            There is parking available at St Michael’s Manor.
          </p>
        </div>

        <div>
          <h3 className="text-l font-semibold">Can I bring my kids?</h3>
          <p className="text-gray-700">
            Unless we’ve specifically invited them, sorry only those on your
            invite can come along.
          </p>
        </div>

        <div>
          <h3 className="text-l font-semibold">What is the dress code?</h3>
          <p className="text-gray-700">
            Black tie – so tuxedo/black suits for the men, and dress to impress
            for the ladies. Hats are welcome if you want to wear one – but not
            mandatory! Be warned, we are hoping to have our wedding outside,
            this will be on the lawn, so stilettos may not be the best choice of
            footwear. There will also be plenty of dancing later, so make sure
            you are comfortable!
          </p>
        </div>

        {!isEveningGuest && (
          <div>
            <h3 className="text-l font-semibold">
              Is the wedding indoors or outdoors?
            </h3>
            <p className="text-gray-700">
              If it is sunny, we are hoping to get married on the hotel lawn. If
              it rains, we have an equally beautiful location to get married
              inside the hotel.
            </p>
          </div>
        )}

        <div>
          <h3 className="text-l font-semibold">Can I take pictures?</h3>
          <p className="text-gray-700">
            For the ceremony, we’d love your phones in your pockets to just
            enjoy us getting married. There will be a photographer to capture
            everything. For the rest of the day, yes please, whip out your
            phones and take pictures of whatever you want – and we want to see
            what you take! There will be QR codes for you to upload your
            pictures which will be shared with everyone who attends.
          </p>
        </div>

        <div>
          <h3 className="text-l font-semibold">What time does it start?</h3>

          {isEveningGuest ? (
            <p className="text-gray-700">
              Please arrive from <span className="font-bold">18:30</span>.
            </p>
          ) : (
            <p className="text-gray-700">
              We will be getting married at{" "}
              <span className="font-bold">13:30</span>, so please arrive to get
              your seat from <span className="font-bold">13:00</span>.
            </p>
          )}
        </div>

        <div>
          <h3 className="text-l font-semibold">
            If I’m staying in a hotel, is there a good time for me to check-in?
          </h3>
          <p className="text-gray-700">
            Yes, after the speeches there should be time (at around{" "}
            <span className="font-bold">18:00</span>) for you to go check-in.
          </p>
        </div>

        <div>
          <h3 className="text-l font-semibold">
            What time does the reception end?
          </h3>
          <p className="text-gray-700">
            The party stops at <span className="font-bold">midnight</span>, so
            if you’re not staying at St Michael’s Manor, please arrange for any
            taxis / onward travel from that time.
          </p>
        </div>
      </div>
    </section>
  );
};
