import { ApolloClient, InMemoryCache } from "@apollo/client";

const uri = "https://api.sarah-and-graham-get-married.com/graphql";

export const client = new ApolloClient({
  uri,
  headers: {
    "x-api-key": "da2-57hggixygrbddjprhjull3yiri",
  },
  cache: new InMemoryCache(),
});
