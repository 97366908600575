export const Button = (props) => {
  const { disabled } = props;
  const styles = "btn";
  const classes = props.className ? `${styles} ${props.className}` : styles;

  return (
    <button
      className={classes}
      onClick={props.clickHandler}
      disabled={disabled}
    >
      {props.children}
    </button>
  );
};
