import { Select } from "../Field/Select";
import { Text } from "../Field/Text";

export const GuestMeal = (props) => {
  const { formData, onChange } = props;
  return (
    <>
      <Text
        label="Allergy/Dietary"
        name="dietary"
        type="textfield"
        value={formData["dietary"]}
        onChange={onChange}
        required={false}
      />
      <Select
        label="Menu Type"
        name="menuType"
        options={["Select", "MEAT", "VEGETARIAN"]}
        value={formData["menuType"]}
        onChange={onChange}
      />
    </>
  );
};
