import { Text } from "../Field/Text";

export const PlusOneContact = (props) => {
  const { formData, onChange } = props;
  return (
    <>
      <Text
        label="Plus 1 First Name"
        name="plusOneFirstName"
        type="text"
        value={formData["plusOneFirstName"]}
        onChange={onChange}
      />
      <Text
        label="Plus 1 Last Name"
        name="plusOneLastName"
        type="text"
        value={formData["plusOneLastName"]}
        onChange={onChange}
      />
    </>
  );
};
