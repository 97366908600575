import logo from "../../images/logo.svg";
import React, { useState } from "react";
import { Link } from "react-scroll";

export const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);

  const menuLinks = [
    "RSVP",
    "Dress Code",
    "Order of the Day",
    "Travel",
    "Accommodation",
    "Taxis",
    "Wedding Gifts",
    "FAQ",
  ];

  return (
    <>
      {/* Header */}
      <header className="bg-white shadow-md sticky top-0 z-10">
        <div className="container mx-auto px-4">
          <div className="hidden md:flex items-center justify-center relative h-[60px]">
            {/* Left Links */}
            <div className="flex space-x-6">
              {menuLinks.slice(0, 4).map((item) => (
                <Link
                  key={item}
                  to={item.toLowerCase().replace(/ /g, "-")}
                  smooth={true}
                  duration={300}
                  offset={-60} // Adjust for sticky header height
                  className="cursor-pointer text-gray-700 hover:text-[#e63d68] transition-colors"
                >
                  {item}
                </Link>
              ))}
            </div>

            {/* Logo */}
            <div className="relative flex items-center justify-center px-6">
              <div className="bg-white rounded-full p-4 shadow-lg">
                <img src={logo} alt="Logo" className="h-16 w-16" />
              </div>
            </div>

            {/* Right Links */}
            <div className="flex space-x-6">
              {menuLinks.slice(4).map((item) => (
                <Link
                  key={item}
                  to={item.toLowerCase().replace(/ /g, "-")}
                  smooth={true}
                  duration={300}
                  offset={-60}
                  className="cursor-pointer text-gray-700 hover:text-[#e63d68] transition-colors"
                >
                  {item}
                </Link>
              ))}
            </div>
          </div>

          {/* Mobile Menu */}
          <div className="md:hidden flex justify-between items-center">
            {/* Logo */}
            <img src={logo} alt="Logo" className="h-10 my-2" />

            {/* Hamburger */}
            <button
              onClick={toggleMobileMenu}
              className="text-gray-800 focus:outline-none"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              </svg>
            </button>
          </div>
        </div>
      </header>

      {/* Mobile Menu Sliding Panel */}
      {isMobileMenuOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-20">
          <div className="fixed top-0 right-0 h-full bg-white w-64 shadow-lg z-30">
            <nav className="flex flex-col p-4 space-y-4">
              {menuLinks.map((item) => (
                <Link
                  key={item}
                  to={item.toLowerCase().replace(/ /g, "-")}
                  smooth={true}
                  duration={500}
                  offset={-60}
                  className="cursor-pointer text-gray-700 hover:text-[#e63d68] transition-colors"
                  onClick={() => setIsMobileMenuOpen(false)} // Close menu after clicking
                >
                  {item}
                </Link>
              ))}
            </nav>
          </div>
        </div>
      )}
    </>
  );
};
