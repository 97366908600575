import heroImage from "../../images/hero-image-colour.jpg";

export const HeroHeader = (props) => {
  return (
    <header className="relative w-full max-h-[400px] overflow-hidden">
      <div>
        <img
          src={heroImage}
          alt="Sarah and Grahams wedding"
          className="w-full h-full object-cover max-h-[500px]"
        />
      </div>

      <div className="absolute inset-0 flex items-center justify-center">
        {props.children}
      </div>
    </header>
  );
};
