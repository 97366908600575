import "./App.css";
import { useState } from "react";
import { PasswordInput } from "./components/Password/PasswordInput";
import { Header } from "./components/Header/Header";
import { Countdown } from "./components/Countdown/Countdown";
import { DressCode } from "./components/DressCode/DressCode";
import { HeroHeader } from "./components/HeroHeader/HeroHeader";
import { OrderOfTheDay } from "./components/OrderOfTheDay/OrderOfTheDay";
import { Travel } from "./components/Travel/Travel";
import { Accommodation } from "./components/Accommodation/Accommodation";
import { Taxis } from "./components/Taxis/Taxis";
import { WeddingGifts } from "./components/WeddingGifts/WeddingGifts";
import { FAQ } from "./components/FAQ/FAQ";
import { RSVP } from "./components/Form/RSVP";

const extractUrlId = () => window.location.pathname.replace(/^\//, "");

function App() {
  const urlId = extractUrlId();

  const [guestType, setGuestType] = useState(undefined);
  const canSeeForm = guestType !== undefined;

  return (
    <div className="App">
      <header className="App-header">
        {canSeeForm ? (
          <>
            <Header />
            <HeroHeader>
              <Countdown />
            </HeroHeader>
            <RSVP guestType={guestType} />
            <DressCode />
            <OrderOfTheDay guestType={guestType} />
            <Travel />
            <Accommodation />
            <Taxis />
            <WeddingGifts />
            <FAQ guestType={guestType} />
          </>
        ) : (
          <PasswordInput urlId={urlId} setGuestType={setGuestType} />
        )}
      </header>
    </div>
  );
}

export default App;
