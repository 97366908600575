export const Travel = (props) => {
  return (
    <section id="travel" className="py-12 space-y-6 max-w-xl mx-auto px-4">
      <h2 className="text-2xl font-semibold text-[#e63d68] mb-6 text-center">
        Travel
      </h2>
      <p>We recommend either travelling by train or car.</p>
      <p>
        There is a car park at the hotel, and the closest train station is a
        28-minute walk – however Uber works in St Albans if you want to save
        your feet!
      </p>
      <p>
        <a
          href="https://www.google.co.uk/maps/dir//St+Michael's+Manor+Hotel,+Fishpool+St,+St+Albans+AL3+4RY/@51.753188,-0.3531814,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x48763f3b93f5529f:0x252d168522fb3001!2m2!1d-0.3493191!2d51.7532939!3e0?entry=ttu&g_ep=EgoyMDI0MTExMy4xIKXMDSoASAFQAw%3D%3D"
          className="btn"
          target="_blank"
          rel="noopener noreferrer"
        >
          Find St Michael's Manor Hotel
        </a>
      </p>
    </section>
  );
};
