import React, { useEffect, useState } from "react";

// Set target date: 3 May 2025, 1pm
const weddingDate = new Date("2025-05-03T13:00:00").getTime();

function calculateTimeLeft(targetDate) {
  const now = new Date().getTime();
  const difference = targetDate - now;

  return {
    days: Math.floor(difference / (1000 * 60 * 60 * 24)),
    hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
    minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
    seconds: Math.floor((difference % (1000 * 60)) / 1000),
    total: difference,
  };
}

export const Countdown = () => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(weddingDate));

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft(weddingDate));
    }, 1000);

    return () => clearInterval(timer); // Clean up timer on component unmount
  }, []);

  if (timeLeft.total < 0) {
    // Past event message
    return (
      <div className="text-center text-3xl text-[#e63d68] font-bold">
        The wedding has already happened. Congratulations to the couple! 🎉
      </div>
    );
  }

  return (
    <div className="flex justify-center gap-4 mt-8 flex-wrap">
      {[
        { label: "Days", value: timeLeft.days },
        { label: "Hours", value: timeLeft.hours },
        { label: "Minutes", value: timeLeft.minutes },
        { label: "Seconds", value: timeLeft.seconds },
      ].map((timePart) => (
        <div
          key={timePart.label}
          className="w-16 h-16 sm:w-20 sm:h-20 md:w-24 md:h-24 bg-[#e63d68] bg-opacity-80 text-white flex flex-col justify-center items-center rounded-lg shadow-lg"
        >
          <div className="text-2xl sm:text-3xl md:text-4xl font-bold">
            {timePart.value || "0"}
          </div>
          <div className="text-xs sm:text-sm uppercase">{timePart.label}</div>
        </div>
      ))}
    </div>
  );
};
